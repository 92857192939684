<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.bookkeepingManagement') }}</h1>
      <p v-if="customer" class="fw-bold">{{ customer.name }}</p>
    </template>
    <div v-if="billingsLoading" v-loading="billingsLoading" style="height: 50vh" />
    <template v-else-if="!loading && sentDate && billingsExport">
      <BillingsExport :billings="billings" :sent-date="sentDate" :billings-export-id="billingsExport.id" />
    </template>
  </PageLayout>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';

import { PageLayout } from '@/modules/core';

import { useBillings } from '@/modules/bookkeeping/composition/billing';

import { formatDate } from './formatters';
import { useBusiness, useBillingsExport, useBillingEntries } from './compositions';
import BillingsExport from './components/BillingsExport';

export default {
  name: 'BookkeeperBillingsExport',
  components: { PageLayout, BillingsExport },
  setup() {
    const root = getCurrentInstance().proxy;
    const { business: customer } = useBusiness(computed(() => ({ businessId: root.$route.params.customerId })));

    const { billingsExport } = useBillingsExport(computed(() => ({ id: root.$route.params.id })));
    const { billingEntries, loading: billingEntriesLoading } = useBillingEntries(
      computed(() => ({ billingsExportId: root.$route.params.id }))
    );

    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        ids: billingEntriesLoading.value ? [] : billingEntries.value.map(({ billingId }) => billingId),
        businessId: root.$route.params.customerId,
      }))
    );

    return {
      customer,
      billingsExport,
      billings,
      billingsLoading,
      loading: computed(() => billingEntriesLoading.value || billingsLoading.value),
    };
  },
  computed: {
    sentDate() {
      return this.billingsExport?.createdAt;
    },
  },
  methods: {
    formatDate,
  },
};
</script>
