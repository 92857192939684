<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.dataTransfer') }}</h1>
    </template>

    <BookkeeperAggregationsTable :bookkeeper-aggregations="bookkeeperAggregations" :loading="loading" />
  </PageLayout>
</template>

<script>
import { computed } from 'vue';

import { PageLayout } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useBusinessRelationsGet } from '@/modules/permissions';

import { useBookkeeperAggregations } from './compositions';
import { BookkeeperAggregationsTable } from './components';

export default {
  components: {
    PageLayout,
    BookkeeperAggregationsTable,
  },
  setup() {
    const { currentTenantId } = useTenancy();
    const { businessRelations, loading: relationsLoading } = useBusinessRelationsGet(
      computed(() => ({ receiverBusinessId: currentTenantId.value }))
    );

    const { bookkeeperAggregations, loading: aggregationsLoading } = useBookkeeperAggregations(
      computed(() => ({ bookkeeperId: currentTenantId.value }))
    );

    const loading = computed(() => aggregationsLoading.value || relationsLoading.value);

    const aggregations = computed(() =>
      businessRelations.value.nodes.reduce(
        (result, { providerBusinessId, providerBusinessName }) => ({
          ...result,
          [providerBusinessId]: bookkeeperAggregations?.value[providerBusinessId] ?? {
            count: 0,
            customerName: providerBusinessName,
            lastBatch: null,
          },
        }),
        {}
      )
    );

    return { bookkeeperAggregations: aggregations, loading };
  },
};
</script>
