<template>
  <div>
    <PopupLayout width="25rem" @close="$emit('close')">
      <template #title>
        <h2 class="title">{{ $t('bookkeeper.settings.addSupplierAccounts') }}</h2>
      </template>
      <template #body>
        <el-form ref="form" :model="formModel" :show-message="false">
          <el-form-item :label="$t('bookkeeper.settings.supplier')" prop="supplierId" class="m-0" required>
            <el-select v-model="formModel.supplierId" :style="{ width: '100%' }" filterable autocomplete="on">
              <el-option
                v-for="supplier in suppliers"
                :key="supplier.id"
                :label="`${supplier.number} / ${supplier.name}`"
                :value="supplier.id"
                style="width: 23rem"
                class="text-truncate"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('bookkeeping.exportWizard.fields.debitAccount')"
            prop="debitAccountNumber"
            class="my-4"
            required
          >
            <el-input v-model="formModel.debitAccountNumber" />
          </el-form-item>
          <el-form-item
            :label="$t('bookkeeping.exportWizard.fields.creditAccount')"
            prop="creditAccountNumber"
            class="m-0"
            required
          >
            <el-input v-model="formModel.creditAccountNumber" />
          </el-form-item>
          <el-form-item
            :label="$t('bookkeeping.exportWizard.fields.codeAccount')"
            prop="codeAccountNumber"
            class="m-0"
            required
          >
            <el-input v-model="formModel.codeAccountNumber" />
          </el-form-item>
        </el-form>
      </template>
      <template #buttons>
        <div>
          <Button type="secondary" @click="$emit('close')">
            {{ $t('commons.cancel') }}
          </Button>
          <Button type="primary" @click="openConfirmModal">
            {{ $t('commons.save') }}
          </Button>
        </div>
      </template>
    </PopupLayout>
    <ConfirmSaveDefaultAccount
      v-if="isConfirmModalOpen"
      :type="[ACCOUNT_TYPES.DEBIT, ACCOUNT_TYPES.CREDIT]"
      @confirm="confirm"
      @close="isConfirmModalOpen = false"
    />
  </div>
</template>

<script>
import { ref, reactive, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core';

import PopupLayout from './PopupLayout';
import ConfirmSaveDefaultAccount from '../exportWizard/components/ConfirmSaveDefaultAccount';
import { ACCOUNT_TYPES } from '../constants';

export default {
  components: { PopupLayout, Button, ConfirmSaveDefaultAccount },
  props: {
    suppliers: { type: Array, required: true },
  },
  setup(_, { emit }) {
    const root = getCurrentInstance().proxy;
    const formModel = reactive({
      supplierId: null,
      debitAccountNumber: null,
      creditAccountNumber: null,
      codeAccountNumber: null,
    });
    const isConfirmModalOpen = ref(false);

    return {
      ACCOUNT_TYPES,
      formModel,
      isConfirmModalOpen,
      openConfirmModal: () => {
        root.$refs.form.validate((valid) => {
          if (valid) isConfirmModalOpen.value = true;
        });
      },
      confirm: ({ override }) => {
        emit('save', { ...formModel, override });
      },
    };
  },
};
</script>

<style scoped lang="scss">
.word-break {
  word-break: normal;
}

:deep(.popup-layout .el-dialog__body) {
  padding-top: 0;
}
</style>
