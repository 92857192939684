<template>
  <div>
    <div class="mb-4 d-flex justify-content-between align-items-center">
      <h3>
        {{ $t('bookkeeping.sentDocuments.subTitle', { date: formatDate(sentDate) }) }}
      </h3>
      <span>
        <Button type="primary" :disabled="!billingsExportFilesLink" @click="resendModalOpen = true">
          {{ $t('bookkeeping.sendModal.title.dataTransferAgain') }}
        </Button>
      </span>
    </div>
    <Table
      :columns="columns"
      :data="paginatedData"
      :active-sort="activeSort"
      show-index
      rounded
      border
      @row-click="handleRowClick"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageSize * currentPageIndex }}
      </template>
      <template #cell-recordDate="{ rowData: { recordDate } }">
        {{ formatDate(recordDate) }}
      </template>
      <template #cell-issueDate="{ rowData: { issueDate } }">
        {{ formatDate(issueDate) }}
      </template>
      <template #filter-supplier>
        <el-input
          v-model="supplierSearchFilter"
          :placeholder="$t('commons.searchWithDots')"
          class="text-typography-primary"
        >
          <template #prefix>
            <div class="d-flex align-items-center h-100">
              <SearchIcon width="20px" height="20px" />
            </div>
          </template>
        </el-input>
      </template>
      <template #cell-documentType="{ rowData: { documentType } }">
        <TruncatedText>{{ $t(`document.exports.schema.type.shortName.${documentType}`) }}</TruncatedText>
      </template>
      <template #cell-netAmount="{ rowData: { netAmount } }">
        {{ formatCentsToCurrency(netAmount) ?? '-' }}
      </template>
      <template #cell-taxAmount="{ rowData: { taxAmount } }">
        {{ formatCentsToCurrency(taxAmount) ?? '-' }}
      </template>
      <template #cell-totalAmount="{ rowData: { totalAmount } }">
        {{ formatCentsToCurrency(totalAmount) ?? '-' }}
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="filteredTableData.length && filteredTableData.length > pageSize"
        layout="prev, pager, next, jumper"
        small
        background
        :total="filteredTableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
    </div>

    <DocumentModal
      v-if="displayedDocument"
      visible
      :document-id="displayedDocument"
      @close="displayedDocument = null"
    />

    <CompleteDataModal
      v-if="resendModalOpen && !billingsExportFilesLinkLoading"
      :total-entries-exported="billings.length"
      @download-file="downloadFile"
      @close="resendModalOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue';

import { Table, TruncatedText, Button } from '@/modules/core';
import { SearchIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import CompleteDataModal from './CompleteDataModal';
import { formatDate } from '../formatters';
import { useBillingsExportFilesLink } from '../compositions';

const TABLE_HEADERS = {
  RECORD_DATE: 'recordDate',
  ISSUE_DATE: 'issueDate',
  SUPPLIER: 'supplier',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  NET_AMOUNT: 'netAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
};

const getSortCompare = (direction, columnKey) => {
  switch (columnKey) {
    case TABLE_HEADERS.RECORD_DATE:
      return (a, b) => (a.recordDate - b.recordDate) * direction;

    case TABLE_HEADERS.ISSUE_DATE:
      return (a, b) => (a.issueDate - b.issueDate) * direction;

    case TABLE_HEADERS.SUPPLIER:
      return (a, b) => a.supplier.localeCompare(b.supplier) * direction;

    case TABLE_HEADERS.TOTAL_AMOUNT:
      return (a, b) => (a.totalAmount - b.totalAmount) * direction;

    default:
      return () => 0;
  }
};

export default {
  components: { Table, Button, DocumentModal, CompleteDataModal, SearchIcon, TruncatedText },
  props: {
    billings: { type: Array, required: true },
    sentDate: { type: Number, required: true },
    billingsExportId: { type: String, required: true },
  },
  setup(props) {
    const { billingsExportFilesLink, loading: billingsExportFilesLinkLoading } = useBillingsExportFilesLink({
      id: props.billingsExportId,
    });
    const { formatCentsToCurrency } = useCurrency();
    return {
      formatCentsToCurrency,
      billingsExportFilesLink,
      billingsExportFilesLinkLoading,
      activeSort: ref({ direction: -1, columnKey: TABLE_HEADERS.RECORD_DATE }),
      resendModalOpen: ref(false),
      displayedDocument: ref(null),
      currentPageIndex: ref(0),
      supplierSearchFilter: ref(''),
      pageSize: 50,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translateTableHeader('recordDate'),
          key: TABLE_HEADERS.RECORD_DATE,
          width: '120px',
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.RECORD_DATE }),
        },
        {
          header: this.translateTableHeader('issueDate'),
          key: TABLE_HEADERS.ISSUE_DATE,
          width: '120px',
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.ISSUE_DATE }),
        },
        {
          header: this.translateTableHeader('supplier'),
          key: TABLE_HEADERS.SUPPLIER,
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.SUPPLIER }),
          width: '200px',
          filterActive: !!this.supplierSearchFilter,
        },
        {
          header: this.translateTableHeader('documentType'),
          key: TABLE_HEADERS.DOCUMENT_TYPE,
          width: '100px',
        },
        {
          header: this.translateTableHeader('documentNumber'),
          key: TABLE_HEADERS.DOCUMENT_NUMBER,
        },
        {
          header: this.translateTableHeader('netAmount'),
          key: TABLE_HEADERS.NET_AMOUNT,
        },
        {
          header: this.translateTableHeader('taxAmount'),
          key: TABLE_HEADERS.TAX_AMOUNT,
        },
        {
          header: this.translateTableHeader('totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.TOTAL_AMOUNT }),
        },
      ];
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },

    filteredTableData() {
      return this.billings
        .map((billing) => {
          const {
            id: documentId,
            recordedAt,
            issueDate,
            type: documentType,
            documentNumber,
          } = billing.eventReferences.find(({ regulatory }) => regulatory).document;
          return {
            [TABLE_HEADERS.RECORD_DATE]: recordedAt,
            [TABLE_HEADERS.ISSUE_DATE]: issueDate,
            [TABLE_HEADERS.SUPPLIER]: billing.supplier.name,
            [TABLE_HEADERS.DOCUMENT_TYPE]: documentType,
            [TABLE_HEADERS.DOCUMENT_NUMBER]: documentNumber,
            [TABLE_HEADERS.NET_AMOUNT]: billing.netAmount,
            [TABLE_HEADERS.TAX_AMOUNT]: billing.taxAmount,
            [TABLE_HEADERS.TOTAL_AMOUNT]: billing.totalAmount,
            documentId,
          };
        })
        .filter(({ supplier }) => supplier.includes(this.supplierSearchFilter))
        .sort(getSortCompare(this.activeSort.direction, this.activeSort.columnKey));
    },
    paginatedData() {
      return this.filteredTableData.slice(
        this.currentPageIndex * this.pageSize,
        this.currentPageIndex * this.pageSize + this.pageSize
      );
    },
  },
  methods: {
    formatDate,
    handleRowClick(rowIndex) {
      this.displayedDocument = this.filteredTableData[rowIndex].documentId;
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.unsentBillings.table.headers.${columnKey}`);
    },
    async downloadFile() {
      window.open(this.billingsExportFilesLink, '_blank');
    },
  },
};
</script>
