<template>
  <div>
    <PageLayout class="container">
      <template #header>
        <h1>{{ $t('bookkeeper.dataTransfer.title') }}</h1>
        <p class="fw-bold">{{ customer.name }}</p>
      </template>
      <template #tabs>
        <Tabs :tabs="tabs" :active-tab.sync="activeTab" @click="handleTabClick" />
      </template>
      <router-view
        :customer="customer"
        :billing-entries="billingEntries"
        :billing-entries-loading="billingEntriesLoading"
        :billings-exports="billingsExports"
        @refetch="refetch"
        @refetch-billing-entries="billingEntriesRefetch"
      />
    </PageLayout>
  </div>
</template>

<script type="text/javascript">
import { ref, watch, computed, getCurrentInstance } from 'vue';

import { PageLayout, Tabs } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import { useBusiness, useBillingEntries, useBillingsExports } from './compositions';

export default {
  name: 'BookkeeperDataTransfer',
  components: { PageLayout, Tabs },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenantId } = useTenancy();
    const activeTab = ref(null);
    const { business: customer } = useBusiness(computed(() => ({ businessId: root.$route.params.customerId })));

    const queryOptions = computed(() => ({ enabled: !!root.$route.params.customerId }));

    const {
      billingEntries,
      totalCount: billingEntriesCount,
      loading: billingEntriesLoading,
      refetch: billingEntriesRefetch,
    } = useBillingEntries(
      computed(() => ({
        bookkeeperId: currentTenantId.value,
        customerId: root.$route.params.customerId,
        exported: false,
      })),
      queryOptions
    );

    const { billingsExports, refetch: billingsExportsRefetch } = useBillingsExports(
      computed(() => ({
        bookkeeperId: currentTenantId.value,
        customerId: root.$route.params.customerId,
        exported: false,
      })),
      queryOptions
    );

    const tabs = computed(() => [
      {
        text: root.$t('bookkeeper.dataTransfer.tabs.unsent'),
        path: 'unsent',
        badgeValue: billingEntriesCount.value,
      },
      {
        text: root.$t('bookkeeper.dataTransfer.tabs.sent'),
        path: 'sent',
        badgeValue: billingsExports.value.length,
      },
      {
        text: root.$t('commons.settings'),
        path: 'settings',
      },
    ]);

    const handleTabClick = (tabIndex) => {
      const selectedTab = tabs.value[tabIndex];
      root.$router.push({ path: selectedTab.path });
    };

    watch(
      () => root.$route.name,
      (name) => {
        activeTab.value = tabs.value.findIndex((t) => t.path === name);
      },
      { immediate: true }
    );

    const refetch = () => {
      billingEntriesRefetch();
      billingsExportsRefetch();
    };

    return {
      customer,
      tabs,
      activeTab,
      handleTabClick,
      billingEntries,
      billingEntriesLoading,
      billingEntriesRefetch,
      billingsExports,
      refetch,
    };
  },
};
</script>
<style scoped>
.container {
  min-width: 1480px;
}
</style>
